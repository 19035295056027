<template>
  <b-container
    fluid
  >
    <b-row
      class="pb-5 mx-1"
    >
      <b-col
        cols="12"
        class="px-0"
      >
        <b-row
          class="py-2 mx-0"
          style="border-radius: 0; border-bottom: 1px solid #dadada;"
        >
          <b-col
            cols="12"
            md="4"
          >
            <router-link :to="{ name: 'lm.purchase-orders.approvals', params: { approver: 'pending' }}">
              <Card
                :loading="fetching"
                :count="approvals.pending"
                :border-right="borderRight"
                title="Pending"
                sub-title="PO Approvals"
                variant="dark"
                icon="fad fa-file-lines"
              />
            </router-link>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <router-link :to="{ name: 'lm.purchase-orders.approvals', params: { approver: 'histories', status: 'approved' }}">
              <Card
                :loading="fetching"
                :count="approvals.approved"
                :border-right="borderRight"
                title="Approved"
                sub-title="PO Approvals"
                variant="success"
                icon="fad fa-file-check"
              />
            </router-link>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <router-link :to="{ name: 'lm.purchase-orders.approvals', params: { approver: 'histories', status: 'disapproved' }}">
              <Card
                :loading="fetching"
                :count="approvals.disapproved"
                title="Disapproved"
                sub-title="PO Approvals"
                variant="danger"
                icon="fad fa-file-times"
              />
            </router-link>
          </b-col>

        </b-row>
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'
import { SLMDashboard } from '@/services'
import Card from '@/components/custom/Card'

export default {

  name: 'LogisticsManagerDashboard',

  middleware: ['auth', 'lm'],

  components: {
    Card
  },

  mixins: [
    MISC,
    FORMATTER
  ],

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      borderRight: !this.isMobile(),
      approvals: {
        pending: 0,
        disapproved: 0,
        approved: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {
    getStatistics () {
      this.fetching = true
      return SLMDashboard.getStatistics().then(({ data }) => {
        this.approvals.pending = data.approver_pending
        this.approvals.approved = data.approver_approved
        this.approvals.disapproved = data.approver_disapproved
      }).catch(() => {
        this.approvals.pending = 0
        this.approvals.approved = 0
        this.approvals.disapproved = 0
      }).finally(() => {
        this.fetching = false
      })
    }
  }
}
</script>
